import Header from "../../components/Header";
import React from "react";
import Button from "../../components/Button/button";
import {useParams} from "react-router-dom";

export default function ReproveOwner() {
  let {linkID} = useParams();

return (
    <div className="h-screen bg-white flex flex-col">
      <Header/>

      <div
          className="flex flex-col flex-1 justify-center text-black w-[clamp(240px,90%,564px)] mx-auto">
        <h3 className="text-4xl text-left leading-7 font-bold text-blue-30">
          Compra não autorizada
        </h3>

        <p className="mt-4 text-left">
          O titular deste telefone é o proprietário da loja. <br/>
          Você pode tentar de novo com o número de telefone de outra pessoa.
        </p>
      </div>

      <div className="mb-5 w-[clamp(240px,90%,564px)] mx-auto">
        <Button
            variant="solid"
            type="navigation"
            to={`/links/${linkID}/`}
        >
          Tentar de novo
        </Button>
      </div>
    </div>
);
}
